<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #e1e1e1;
  width: 0px;
  height: 6px;
  border: 2px solid transparent;
  background-clip: content-box;
}

html {
  height: 100%;
  min-width: 1024px;
}
body {
  width: 100%;
  height: 100%;
}
</style>
