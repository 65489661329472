import App from "../App";

export default [
  {
    path: "/",
    component: App,
    children: [
      {
        path: "",
        component: (r) =>
          require.ensure([], () => r(require("../views/index")), "home"),
      },
      {
        path: "/nativeTask",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/affairsList")),
            "nativeTask"
          ),
      },
      {
        path: "/legalTask",
        component: (r) =>
          require.ensure([], () => r(require("../views/search")), "search"),
      },
      {
        path: "/convenienceApp",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/livelihood")),
            "convenienceApp"
          ),
      },
      {
        path: "/consult",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/askHis.vue")),
            "consult"
          ),
      },
      {
        path: "/addConsult",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/addAsk.vue")),
            "addConsult"
          ),
      },
      {
        path: "/convenienceApp/IdCardQuery",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/convenienceApp/checkIdCard.vue")),
            "checkIdCard"
          ),
      },
      {
        path: "/convenienceApp/internet",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/convenienceApp/internet.vue")),
            "internet"
          ),
      },
      {
        path: "/convenienceApp/convenienceForm",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/convenienceApp/convenienceForm.vue")),
            "convenienceForm"
          ),
      },
      {
        path: "/convenienceApp/contentPage",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/convenienceApp/contentPage.vue")),
            "contentPage"
          ),
      },
      {
        path: "/consultDetails",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/askDetail.vue")),
            "consultDetails"
          ),
      },
      {
        path: "/demandDetail",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/demandDetail.vue")),
            "demandDetail"
          ),
      },
      {
        path: "/me",
        component: (r) =>
          require.ensure([], () => r(require("../views/me")), "me"),
      },
      {
        path: "/interprovincial",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/interprovincial")),
            "interprovincial"
          ),
      },
      {
        path: "/evaluationDetails",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/evaluationDetails")),
            "evaluationDetails"
          ),
      },
      {
        path: "/searchs",
        component: (r) =>
          require.ensure([], () => r(require("../views/searchs")), "searchs"),
      },
      {
        path: "/login",
        component: (r) =>
          require.ensure([], () => r(require("../views/login")), "login"),
      },
      {
        path: "/register",
        component: (r) =>
          require.ensure([], () => r(require("../views/register")), "register"),
      },
      {
        path: "/forgetpassword",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/forgetpassword")),
            "forgetpassword"
          ),
      },
      {
        path: "/service",
        component: (r) =>
          require.ensure([], () => r(require("../views/service")), "service"),
      },
      {
        path: "/individualForgetPassword",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/individualForgetPassword")),
            "individualForgetPassword"
          ),
      },
      {
        path: "/businessDetail",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/businessDetail")),
            "businessDetail"
          ),
      },
      {
        path: "/guideDetails",
        component: (r) =>
          require.ensure(
            [],
            () => r(require("../views/guideDetails")),
            "guideDetails"
          ),
      },
    ],
  },
];
